import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate for URL parameters and navigation
import { Grid, Box, Typography } from '@mui/material';

const Dashboard = ({ selectedParentId }) => {
    const { id } = useParams(); // Get the dynamic 'id' from the URL
    const [sections, setSections] = useState([]);
    const navigate = useNavigate(); // useNavigate hook for navigation

    // Fetch menu items based on the selected ParentId
    useEffect(() => {
        if (id) {
            fetch(`https://app-gleasonreelapi-dev-gvbjb2afcmepacdb.eastus2-01.azurewebsites.net/api/Menu?parentid=${id}`)
                .then(response => response.json())
                .then(data => {
                    // Filter the sections based on parentid matching the current id
                    const filteredSections = data.filter(section => section.parentid === parseInt(id));
                    setSections(filteredSections);
                })
                .catch(error => console.error('Error fetching sections:', error));
        }
    }, [id]);  // Re-run if the 'id' changes

    // Handle the card click to navigate to CreateNewQuote page
    const handleCardClick = (sectionId) => {
        navigate(`/customer-details?sectionId=${sectionId}`); // Navigate to CreateNewQuote with the sectionId as a query parameter
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 4 }}>
                Create New Quote
            </Typography>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {sections.map((section, index) => (
                    <Grid item xs={12} sm={6} md={6} key={section.id}>
                        <Box
                            className="card"
                            onClick={() => handleCardClick(section.id)} // Trigger navigation on click
                        >
                            <Box sx={{ flex: 1, paddingRight: '16px' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                    {section.title}
                                </Typography>
                                <Typography variant="body2" sx={{ marginTop: '20px' }}>
                                    {section.description || 'No description available'}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: 0 }}>
                                <img
                                    src={section.logo || '/images/section.png'} // Use a default image if section.image is not provided
                                    alt={section.title}
                                    style={{
                                        width: '81px',
                                        height: '81px',
                                        borderRadius: '8px',
                                        opacity: 1,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Dashboard;
