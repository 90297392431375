import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, Menu, MenuItem, Divider, Typography, Drawer, CssBaseline } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dashboard from './components/dashboard';
import CreateNewQuote from './components/CreateNewQuote';
import MenuList from './components/menuList'; // Import MenuList
import './App.css'; // Import external CSS file
import { createTheme, ThemeProvider } from '@mui/material';
import CreateNewPackage from './components/CreateNewPackage';
import AddCablePopup from './components/AddCablePopup';

import NewPackage from './components/NewPackage';

// Create a custom theme
const theme = createTheme({
    typography: {
        fontFamily: "'Gotham', sans-serif",
    },
});

function App() {
    const [selectedIndex, setSelectedIndex] = useState(0); // Default selected index is the first item (0)
    const [selectedParentId, setSelectedParentId] = useState(null); // State for the selected Parent ID (for sub-menu)
    const location = useLocation(); // Access the current location
    const [userName] = useState("John Doe");
    const [companyName] = useState("ABC Corporation");
    const [anchorEl, setAnchorEl] = useState(null); // For user dropdown menu
    const navigate = useNavigate();

    const handleListItemClick = (index, itemId) => {
        setSelectedIndex(index); // Update the selected index when a menu item is clicked
        setSelectedParentId(itemId); // Pass the selected parentId to load sub-menu in the dashboard
        navigate(`/dashboard/${itemId}`); // Navigate to the Dashboard with the itemId in the URL
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Sync selectedIndex based on the current location path
    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            // Automatically select the first menu item and display its dashboard
            setSelectedIndex(0);
            setSelectedParentId(1);  // Assuming the first menu item has id 1
            navigate(`/dashboard/1`);
        }
        else if (path === '/manage-quotes') setSelectedIndex(1);
        else if (path === '/manage-customer') setSelectedIndex(2);
        else if (path === '/reports') setSelectedIndex(3);
    }, [location]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
                {/* AppBar (Header) */}
                <AppBar
                    position="fixed"
                    className='appBar'
                    sx={{
                        width: '100%',
                        zIndex: (theme) => theme.zIndex.drawer + 1, // Make sure AppBar is above the Drawer
                    }}
                >
                    <Toolbar>
                        <img src="/images/logo.png" alt="Logo" style={{ height: 40 }} />
                        <Box sx={{ flexGrow: 1 }} />
                        <Box className="userDropdown" onClick={handleProfileMenuOpen}>
                            <Box>
                                <Typography variant="body1" className="userName">
                                    {userName}
                                </Typography>
                                <Typography variant="body3" className="companyName">
                                    {companyName}
                                </Typography>
                            </Box>
                            <ExpandMoreIcon className="icon" />
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem>Profile</MenuItem>
                            <MenuItem>Settings</MenuItem>
                            <Divider />
                            <MenuItem>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                {/* Side Navigation (Drawer) */}
                <Drawer variant="permanent" anchor="left">
                    <MenuList
                        selectedIndex={selectedIndex}
                        handleListItemClick={handleListItemClick}
                        setSelectedParentId={setSelectedParentId} // Passing the setter function to handle the submenu
                    />
                </Drawer>

                {/* Main Content Area */}
                <Box component="main" className='mainContent' sx={{  padding: 3 }}>
                    <Routes>
                        <Route path="/" element={<Dashboard selectedParentId={selectedParentId} />} />
                        <Route path="/customer-details" element={<CreateNewQuote />} />
                        {/* Dynamic route for dashboard with a parameter */}
                        <Route path="/dashboard/:id" element={<Dashboard selectedParentId={selectedParentId} />} />
                        {/*<Route path="/CreateNewPackage" element={<CreateNewPackage />} />*/}
                        {/*<Route path="/" element={<CreateNewPackage />} />*/}

                        <Route path="/NewPackage" element={<NewPackage />} />
                        <Route path="/" element={<NewPackage />} />
                        {/*<Route path="/AddCablePopup" element={<AddCablePopup />} />*/}
                        {/*<Route path="/" element={<AddCablePopup />} />*/}
                        {/* Add other routes as needed */}
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;
