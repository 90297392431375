import React from 'react';
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Autocomplete,
    InputAdornment,
    Paper,
    Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CustomerDetailsAccordion = ({ formData, setFormData, companyList, filteredCompanyResults, setFilteredCompanyResults, filteredContactResults, setFilteredContactResults, focusedField, handleFocus, handleBlur }) => {

    const handleCompanySearch = (event, value) => {
        if (value && value.trim()) {
            const results = companyList.filter((company) =>
                company.name && company.name.toLowerCase().includes(value.toLowerCase())  // Check if company.name exists
            );
            setFilteredCompanyResults(results);
        } else {
            setFilteredCompanyResults(companyList);
        }
    };


    const handleCompanySelect = (event, company) => {
        if (company) {
            setFormData({
                ...formData,
                companyName: company.name,
                contactPerson: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                phoneNumber: '',
                faxNumber: '',
                emailAddress: '',
            });
            setFilteredContactResults(company.contacts || []);
        } else {
            setFormData({
                companyName: '',
                contactPerson: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                phoneNumber: '',
                faxNumber: '',
                emailAddress: '',
            });
            setFilteredContactResults([]);
        }
    };

    const handleContactSelect = (event, contact) => {
        if (contact) {
            setFormData({
                ...formData,
                contactPerson: contact.name,
                addressLine1: contact.address1,
                addressLine2: contact.address2,
                addressLine3: contact.address3,
                phoneNumber: contact.phone,
                faxNumber: contact.fax,
                emailAddress: contact.email,
            });
        } else {
            setFormData({
                ...formData,
                contactPerson: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                phoneNumber: '',
                faxNumber: '',
                emailAddress: '',
            });
        }
    };
    const handleContactSearch = (event, value) => {
        if (value && value.trim()) {
            const results = filteredContactResults.filter((contact) =>
                contact.name && contact.name.toLowerCase().includes(value.toLowerCase())  // Check if contact.name exists
            );
            setFilteredContactResults(results);
        } else {
            setFilteredContactResults(filteredContactResults);
        }
    };

    return (

        <Paper sx={{ padding: 3 }} sx={{ boxShadow: 'none' }}>
            <Box variant="body3" sx={{ margin: '23px 0 10px' }}>
                Please search for the company details below. If it's a new customer, you can directly enter the company's information.
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="companyName" sx={{ fontWeight: focusedField === 'companyName' ? 'bold' : 'normal' }}>
                            Company Name
                        </FormLabel>
                        <Autocomplete
                            id="companyName"
                            options={filteredCompanyResults}
                            getOptionLabel={(option) => option.companyName || ''}  // Use companyName instead of name
                            onInputChange={handleCompanySearch}
                            onChange={handleCompanySelect}
                            value={filteredCompanyResults.find((company) => company.companyName === formData.companyName) || null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Company Name"
                                    variant="outlined"
                                    fullWidth
                                    onFocus={() => handleFocus('companyName')}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="contactPerson" sx={{ fontWeight: focusedField === 'contactPerson' ? 'bold' : 'normal' }}>
                            Contact Person
                        </FormLabel>
                        <Autocomplete
                            id="contactPerson"
                            options={filteredContactResults}
                            getOptionLabel={(option) => option.customerName || ''}  // Use customerName instead of name
                            onInputChange={handleContactSearch}
                            onChange={handleContactSelect}
                            value={filteredContactResults.find((contact) => contact.customerName === formData.contactPerson) || null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Contact Person"
                                    variant="outlined"
                                    fullWidth
                                    onFocus={() => handleFocus('contactPerson')}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                {/* Address Fields */}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
                        <TextField id="addressLine1" placeholder="Address Line 1" value={formData.addressLine1} fullWidth variant="outlined" disabled />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="addressLine2">Address Line 2</FormLabel>
                        <TextField id="addressLine2" placeholder="Address Line 2" value={formData.addressLine2} fullWidth variant="outlined" disabled />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="addressLine3">Address Line 3</FormLabel>
                        <TextField id="addressLine3" placeholder="Address Line 3" value={formData.addressLine3} fullWidth variant="outlined" disabled />
                    </FormControl>
                </Grid>
                {/* Phone Number */}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                        <TextField id="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} fullWidth variant="outlined" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="faxNumber">Fax Number (Optional)</FormLabel>
                        <TextField id="faxNumber" placeholder="Fax Number" value={formData.faxNumber} fullWidth variant="outlined" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormLabel htmlFor="emailAddress">Email Address (Optional)</FormLabel>
                        <TextField id="emailAddress" placeholder="Email Address" value={formData.emailAddress} fullWidth variant="outlined" />
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomerDetailsAccordion;
