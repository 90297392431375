import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CustomerDetailsAccordion from './CustomerDetailsAccordion';
import PackageDetailsAccordion from './PackageDetailsAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CreateNewQuote = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        contactPerson: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        phoneNumber: '',
        faxNumber: '',
        emailAddress: '',
    });
    const [companyList, setCompanyList] = useState([]);
    const [filteredCompanyResults, setFilteredCompanyResults] = useState([]);
    const [filteredContactResults, setFilteredContactResults] = useState([]);
    const [expanded, setExpanded] = useState('panel1');
    const [focusedField, setFocusedField] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [packageDetails, setPackageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://app-gleasonreelapi-dev-gvbjb2afcmepacdb.eastus2-01.azurewebsites.net/api/Company?User=hyogesh');
                if (!response.ok) {
                    throw new Error('Failed to fetch company data');
                }
                const result = await response.json();
                setCompanyList(result); // Store all companies in companyList
                setFilteredCompanyResults(result); // Initialize the filtered results with all companies
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Function to skip customer details and go to the next accordion
    const handleSkipCustomerDetails = () => {
        setExpanded('panel2'); // Expand the second panel (Package Details)
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error} reels data</div>;

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h6" marginBottom='30px'>
                Reel / Create New Quote
            </Typography>

            {/* Customer Details Accordion */}
            <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')} sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container alignItems="center">
                        <Grid item>
                            <Box className="step-circle">
                                <Typography className="step-circle-text">01</Typography>
                            </Box>
                        </Grid>
                        <Typography variant="body3" className="accordion-title">Customer Details</Typography>
                    </Grid>
                    <Grid>
                        {/* Make SKIP CUSTOMER DETAILS text clickable */}
                        <Typography onClick={handleSkipCustomerDetails} sx={{ cursor: 'pointer', color: 'blue' }}>
                            SKIP CUSTOMER DETAILS
                        </Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <CustomerDetailsAccordion
                        formData={formData}
                        setFormData={setFormData}
                        companyList={companyList}
                        filteredCompanyResults={filteredCompanyResults}
                        setFilteredCompanyResults={setFilteredCompanyResults}
                        filteredContactResults={filteredContactResults}
                        setFilteredContactResults={setFilteredContactResults}
                        focusedField={focusedField}
                        handleFocus={(field) => setFocusedField(field)}
                        handleBlur={() => setFocusedField('')}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Package Details Accordion */}
            <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')} sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Grid container alignItems="center">
                        <Grid item>
                            <Box className="step-circle">
                                <Typography className="step-circle-text">02</Typography>
                            </Box>
                        </Grid>
                        <Typography variant="body3" className="accordion-title">Cable / Hose Input</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <PackageDetailsAccordion
                        selectedPackage={selectedPackage}
                        setSelectedPackage={setSelectedPackage}
                        packageDetails={packageDetails}
                        setPackageDetails={setPackageDetails}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default CreateNewQuote;
