import React from 'react';
import {
    Grid,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Box,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Custom icon

const PackageDetailsAccordion = ({ focusedField, selectedPackage, setSelectedPackage, packageDetails, setPackageDetails }) => {

    const navigate = useNavigate();
    //const handleNavigate = () => {
    //    navigate("/create-package"); // Navigate to the popup screen
    //};

    const handlePackageSelect = (event) => {
        const selectedPkg = event.target.value;
        setSelectedPackage(selectedPkg);

        // Here, fetch package details based on the selected package
        if (selectedPkg) {
            setPackageDetails([
                { qty: 10, style: 'Style 1', type: 'Type 1', width: '100', weight: '200kg' },
                { qty: 20, style: 'Style 2', type: 'Type 2', width: '120', weight: '220kg' },
            ]);
        } else {
            setPackageDetails([]);
        }
    };

    
    //const handleCreateNewPackage = () => {
    //    navigate("/CreateNewPackage")
    //    console.log('Create New Package');
    //};

    const handleNewPackage = () => {
        navigate("/NewPackage")
        console.log('Create New Package');
    };

    return (
        <Grid container justifyContent="space-between" alignItems="end">
            <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="packageSelect" sx={{ fontWeight: focusedField === 'packageSelect' ? 'bold' : 'normal' }}>
                        Selected Package
                    </FormLabel>
                    <Select
                        id="packageSelect"
                        value={selectedPackage}
                        onChange={handlePackageSelect}
                        displayEmpty
                        IconComponent={ExpandMoreIcon}
                    >
                        <MenuItem value="">Select Package</MenuItem>
                        {/* Replace with actual package options */}
                        <MenuItem value="pkg1">Package 1</MenuItem>
                        <MenuItem value="pkg2">Package 2</MenuItem>
                        <MenuItem value="pkg3">Package 3</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Button variant="outlined" color="primary" className='buttonPrimary' onClick={handleNewPackage}>
                    Create A New Package
                </Button>
            </Grid>

            {/* Table to display Package details */}
            {selectedPackage && (
                <TableContainer sx={{ marginTop: 2 }}>
                    <Box className='tableCaption'>
                        Cables Included In Package
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Qty</TableCell>
                                <TableCell>Style</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Width</TableCell>
                                <TableCell>Weight</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packageDetails.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell>{detail.qty}</TableCell>
                                    <TableCell>{detail.style}</TableCell>
                                    <TableCell>{detail.type}</TableCell>
                                    <TableCell>{detail.width}</TableCell>
                                    <TableCell>{detail.weight}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    );
};

export default PackageDetailsAccordion;
