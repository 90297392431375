import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Box, Typography } from '@mui/material';
import { InsertPageBreakOutlined as InsertPageBreakOutlinedIcon, RestorePageOutlined as RestorePageOutlinedIcon, ManageAccountsOutlined as ManageAccountsOutlinedIcon, DescriptionOutlined as DescriptionOutlinedIcon, ManageHistory as DefaultIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function MenuList({ selectedIndex, handleListItemClick, setSelectedParentId }) {
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState([]); // State to store main menu items (parentid = 0 or null)

    useEffect(() => {
        // Fetch the menu items (replace with your actual API endpoint)
        fetch('https://app-gleasonreelapi-dev-gvbjb2afcmepacdb.eastus2-01.azurewebsites.net/api/Menu')
            .then(response => response.json())
            .then(data => {
                const parentItems = data.filter(item => item.parentid === 0 || item.parentid === null);
                setMenuItems(parentItems);
            })
            .catch(error => console.error('Error fetching menu items:', error));
    }, []);

    const handleClick = (index, itemId) => {
        handleListItemClick(index); // Handle the visual selection
        setSelectedParentId(itemId); // Pass the selected parentId to the Dashboard to load sub-menu
        navigate(`/dashboard/${itemId}`); // Navigate to the Dashboard with the itemId in the URL
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90%', justifyContent: 'space-between' }}>
            <List>
                {menuItems.map((item, index) => (
                    <ListItem
                        button
                        key={item.id}
                        className={selectedIndex === index ? 'custom-selected' : ''}
                        onClick={() => handleClick(index, item.id)} // Pass item.id to set the selected menu
                    >
                        <ListItemIcon className="list-item-icon">
                            {/* Dynamically assign icons based on the title */}
                            {item.title === 'Create New Quote' && <DescriptionOutlinedIcon />}
                            {item.title === 'Manage Quotes' && <InsertPageBreakOutlinedIcon />}
                            {item.title === 'Manage Customer' && <ManageAccountsOutlinedIcon />}
                            {item.title === 'Quotes Report' && <RestorePageOutlinedIcon />}
                            {/* Default icon if no title matches */}
                            {!['Create New Quote', 'Manage Quotes', 'Manage Customer', 'Quotes Report'].includes(item.title) && <DefaultIcon />}
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
            {/* Footer Section with Logo and Slogan */}
            <Box sx={{ textAlign: 'center', mb: '0' }}>
                {/* Logo */}
                <img
                    src="/images/logo.png"
                    alt="Company Logo"
                    style={{ width: '80%', maxWidth: '150px' }} // Adjust size as needed
                />
                <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                    "A Hubbell brand" {/* Slogan */}
                </Typography>
            </Box>
        </Box>
    );
}

export default MenuList;
