import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    Table,
    Select,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import '../App.css';

import { Add, Edit, Delete } from "@mui/icons-material";

const NewPackage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [cables, setCables] = useState([]); // List of cables
    const [currentCable, setCurrentCable] = useState(null); // Cable being edited
    const [formValues, setFormValues] = useState({
        quantity: "",
        type: "",
        awg: "",
        conductors: "",
        volts:"",
        partNumber: "",
        diameter: "",
        weight: "",
        bendRadius: "",
        price: "",
    });
    const [openhose, setOpenHose] = useState(false);
    const [hoses, setHoses] = useState([]);
    const [currentHose, setCurrentHose] = useState(null);
    const [hoseValues, setHoseValues] = useState({
        qty: "",
        style: "hose",
        type: "",
        psi: "",
        width: "",
        ddthick: "",
        weight: "",
        bend: "",
        pricePerFoot: "",
        pN: "",
    });
    //const hoseData = [
    //    {
    //        qty: 1,
    //        style: "hose",
    //        type: "Single hose",
    //        psi: "5",
    //        width: "0.188",
    //        ddthick: 5,
    //        weight: "950",
    //        bend: "5",
    //        pricePerFoot: "50",
    //        pN: "PartNumber",
    //    },
    //];


    // Handle input change in the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    // Open the dialog
    const handleOpenDialog = (cable = null) => {
        setOpen(true);
        if (cable) {
            setCurrentCable(cable);
            setFormValues(cable);
        } else {
            setCurrentCable(null);
            setFormValues({
                quantity: "",
                style: "Cable",
                type: "",
                awg: "",
                conductors: "",
                volts: "",
                partNumber: "",
                diameter: "",
                weight: "",
                bendRadius: "",
                price: "",
            });
        }
    };

    // Close the dialog
    const handleCloseDialog = () => {
        setOpen(false);
    };

    // Add or update a cable
    const handleSaveCable = () => {
        if (currentCable) {
            // Update cable
            setCables((prev) =>
                prev.map((cable) =>
                    cable.partNumber === currentCable.partNumber ? formValues : cable
                )
            );
        } else {
            // Add new cable
            setCables((prev) => [...prev, { ...formValues, id: Date.now() }]);
        }
        handleCloseDialog();
    };

    // Delete a cable
    const handleDeleteCable = (id) => {
        setCables((prev) => prev.filter((cable) => cable.id !== id));
    };

    // Handle input change in the Hose form
    const handleInputHoseChange = (e) => {
        const { name, value } = e.target;
        setHoseValues((prev) => ({ ...prev, [name]: value }));
    };


    // Open the hose dialog
    const handleOpenHoseDialog = (hose = null) => {
        setOpenHose(true);
        if (hose) {
            setCurrentHose(hose);
            setHoseValues(hose);
        } else {
            setCurrentHose(null);
            setHoseValues({
                qty: "",
                style: "hose",
                type: "",
                psi: "",
                width: "",
                ddthick: "",
                weight: "",
                bend: "",
                pricePerFoot: "",
                pN: "",
            });
        }
    };

    // Close the dialog
    const handleCloseHoseDialog = () => {
        setOpenHose(false);
    };

    // Add or update a hose
    const handleSaveHose = () => {
        if (currentHose) {
            // Update hose
            setHoses((prev) =>
                prev.map((hose) =>
                    hose.pN === currentHose.pN ? hoseValues : hose
                )
            );
        } else {
            // Add new hose
            setHoses((prev) => [...prev, { ...hoseValues, id: Date.now() }]);
        }
        handleCloseHoseDialog();
    };

    // Delete a hose
    const handleDeleteHose = (id) => {
        setHoses((prev) => prev.filter((hose) => hose.id !== id));
    };

    const handleClose = () => {
        setOpen(false);
        navigate("/"); // Navigate back to MainScreen when the popup is closed
    };

    return (
        //<Modal
        //    open={open}
        //    onClose={handleClose}
        //    aria-labelledby="create-new-package-title"
        //    aria-describedby="create-new-package-description"
        //>
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                maxHeight: "90vh",
                overflowY: "auto",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
            }}
        >
            <Box p={2}>
                <Typography variant="h6" gutterBottom>
                    Create New Package
                </Typography>

                <Grid item xs={12} sm={6}>
                    <FormControl >
                        <FormLabel htmlFor="packageName">Package Name</FormLabel>
                        <TextField id="packageName" placeholder="Enter Package Name" fullWidth variant="outlined" />
                    </FormControl>
                </Grid>
                <Box mt={2}>
                    <Typography variant="h6">Cables Included In Package</Typography>
                    <Button style={{ float: 'right' }}
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => handleOpenDialog()}
                    >
                        Add Cable
                    </Button>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Style</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>AWG</TableCell>
                                    <TableCell>Cond</TableCell>
                                    <TableCell>Volts</TableCell>

                                    <TableCell>Diameter (in)</TableCell>
                                    <TableCell>Weight (lbs/ft)</TableCell>
                                    <TableCell>Bend (in)</TableCell>
                                    <TableCell>Price ($/ft)</TableCell>
                                    <TableCell>PN</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cables.map((cable) => (
                                    <TableRow key={cable.id}>
                                        <TableCell>{cable.quantity}</TableCell>
                                        <TableCell>{cable.style}</TableCell>
                                        <TableCell>{cable.type}</TableCell>
                                        <TableCell>{cable.awg}</TableCell>
                                        <TableCell>{cable.conductors}</TableCell>
                                        <TableCell>{cable.volts}</TableCell>

                                        <TableCell>{cable.diameter}</TableCell>
                                        <TableCell>{cable.weight}</TableCell>
                                        <TableCell>{cable.bendRadius}</TableCell>
                                        <TableCell>{cable.price}</TableCell>
                                        <TableCell>{cable.partNumber}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleOpenDialog(cable)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteCable(cable.id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {cables.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">
                                            No cables added yet.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Hose Included in Package (Empty) */}
                <Typography variant="subtitle1" gutterBottom>
                    Hose Included In Package
                    <div style={{ float: 'right' }}>


                        <Button style={{ float: 'right' }}
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => handleOpenHoseDialog()}
                        >
                            Add Hose
                        </Button>
                    </div>
                </Typography>

                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Qty</TableCell>
                                <TableCell>Style</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>PSI</TableCell>
                                <TableCell>Width (in.)</TableCell>
                                <TableCell>DD/Thick (in.)</TableCell>
                                <TableCell>Wgt (lb/ft)</TableCell>
                                <TableCell>Bend (in.)</TableCell>
                                <TableCell>$ / Foot</TableCell>
                                <TableCell>PN</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {hoses.map((hose) => (
                                <TableRow key={hose.id}>

                                    <TableCell>{hose.qty}</TableCell>
                                    <TableCell>{hose.style}</TableCell>
                                    <TableCell>{hose.type}</TableCell>
                                    <TableCell>{hose.psi}</TableCell>

                                    <TableCell>{hose.width}</TableCell>
                                    <TableCell>{hose.ddthick}</TableCell>
                                    <TableCell>{hose.weight}</TableCell>
                                    <TableCell>{hose.bend}</TableCell>
                                    <TableCell>{hose.pricePerFoot}</TableCell>
                                    <TableCell>{hose.pN}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenHoseDialog(hose)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteHose(hose.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {hoses.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        No hoses added yet.
                                    </TableCell>
                                </TableRow>
                            )}

                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    Please delete all the added cables, to add hose in this Package.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Summary Section */}
                <Box
                    sx={{
                        border: "1px solid #ddd",
                        borderRadius: 2,
                        p: 2,
                        mb: 2,
                        bgcolor: "#f9f9f9",
                    }}
                >

                    <Typography>
                        <strong>Qty. of Cables / Hoses:</strong> 01
                    </Typography>
                    <Typography>
                        <strong>Package Width:</strong> 1.050 inches
                    </Typography>
                    <Typography>
                        <strong>Package Min. Bend Radius:</strong> 3.52 inches
                    </Typography>
                    <Typography>
                        <strong>Package Weight:</strong> 0.240 lbs/feet
                    </Typography>
                    <Typography>
                        <strong>Package Height:</strong> 0.030 inches
                    </Typography>
                </Box>

                <div style={{ float: 'right' }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "orange" }}
                        onClick={handleClose}
                    >
                        Save Package
                    </Button>
                </div>
            </Box>


            {/* Dialog for Adding/Editing Cable */}
            <Dialog open={open} onClose={handleCloseDialog} maxWidth="xl" fullWidth>
                <DialogTitle>
                    {currentCable ? "Edit Cable" : "Add Cable"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        {/* Left Column */}
                        <Grid item xs={6}>
                            <TextField
                                label="Cable Quantity"
                                name="quantity"
                                value={formValues.quantity}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="AWG"
                                name="awg"
                                value={formValues.awg}
                                onChange={handleInputChange}
                               
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                                                
                               />
                            {/*<FormControl fullWidth>*/}

                            {/*    <FormLabel htmlFor="Volts">Volts</FormLabel>*/}

                            {/*    <Select defaultValue="600">*/}
                            {/*        <MenuItem value="600">600</MenuItem>*/}
                            {/*        <MenuItem value="5000">5000</MenuItem>*/}
                            {/*        <MenuItem value="8000">8000</MenuItem>*/}
                            {/*        <MenuItem value="15000">15000</MenuItem>*/}

                            {/*    </Select>*/}
                               
                            {/*</FormControl>*/}
                            <TextField
                                label="Volts"
                                name="volts"
                                value={formValues.volts}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Outside Diameter"
                                name="diameter"
                                value={formValues.diameter}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>inches</span>,
                                }}
                            />
                            <TextField
                                label="Weight"
                                name="weight"
                                value={formValues.weight}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>lbs/ft</span>,
                                }}
                            />

                        </Grid >
                        {/* Right Column */}
                        <Grid item xs={6}>
                            <TextField
                                label="Type"
                                name="type"
                                value={formValues.type}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Conductors"
                                name="conductors"
                                value={formValues.conductors}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Part Number"
                                name="partNumber"
                                value={formValues.partNumber}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />


                            <TextField
                                label="Package Min. Bend Radius"
                                name="bendRadius"
                                value={formValues.bendRadius}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>inches</span>,
                                }}
                            />
                            <TextField
                                label="Price"
                                name="price"
                                value={formValues.price}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>per foot</span>,
                                }}
                            />

                        </Grid >
                    </Grid >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button variant="contained" sx={{ backgroundColor: "orange" }} onClick={handleSaveCable}>
                        {currentCable ? "Update Cable" : "Add Cable"}
                    </Button>
                </DialogActions>
            </Dialog>





            {/* Dialog for Adding/Editing Hose */}
            <Dialog open={openhose} onClose={handleCloseHoseDialog} maxWidth="xl" fullWidth>
                <DialogTitle>
                    {currentHose ? "Edit Hose" : "Add Hose"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        {/* Left Column */}
                        <Grid item xs={6}>
                            <TextField
                                label="Hose Quantity"
                                name="qty"
                                value={hoseValues.qty}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Hose inside Diameter (inches)"
                                name="width"
                                value={hoseValues.width}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Hose Pressure"
                                name="psi"
                                value={hoseValues.psi}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Bend Radius"
                                name="bend"
                                value={hoseValues.bend}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>inches</span>,
                                }}
                            />
                            <TextField
                                label="Hose Price"
                                name="pricePerFoot"
                                value={hoseValues.price}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>per foot</span>,
                                }}
                            />
                        </Grid >
                        {/* Right Column */}
                        <Grid item xs={6}>

                            <TextField
                                label="Hose Contents"
                                name="type"
                                value={hoseValues.type}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="Part Number"
                                name="pN"
                                value={hoseValues.pN}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Outside Diameter"
                                name="ddthick"
                                value={hoseValues.ddthick}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>inches</span>,
                                }}
                            />
                            <TextField
                                label="Hose Weight (w/content)"
                                name="weight"
                                value={hoseValues.weight}
                                onChange={handleInputHoseChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <span>lbs/ft</span>,
                                }}
                            />


                        </Grid >
                    </Grid >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHoseDialog}>Cancel</Button>
                    <Button variant="contained" sx={{ backgroundColor: "orange" }} onClick={handleSaveHose}>
                        {currentHose ? "Update Hose" : "Add Hose"}
                    </Button>
                </DialogActions>
            </Dialog>




        </Box>

    );
};

export default NewPackage;
